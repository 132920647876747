<template>
    <div>
        <Header/> 
        <Detail />
        <Footer data-html2canvas-ignore="true"/>          
    </div>
</template>

<script>
import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import Detail from '@/components/detail/Main.vue';

export default {
    name: 'detail',
    components: {        
        Header,
        Footer,
        Detail
    }, 
}
</script>

<style scoped> 
</style>
