<template>
    <!-- Modal -->
    <teleport to="body">
    <div :class="'modal fade'+(translate_error ? ' show':'')" id="modal-translate-error" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" :style="translate_error ? 'display: block;' : 'display: none;'">
      <div class="modal-dialog" style="max-width:640px;">
          <div class="modal-content">
              <div class="confirm-feedback">
                <p>{{$t('The automated translation encountered an error and has been reported to Prioritis team.')}}</p>
              </div>
              <div class="box-btn-menu mx-auto">                      
                    <button data-description="ICON Cancel" data-bs-dismiss="modal" aria-label="Close" class="cancel btn btn-border-orange" @click="close_popup()"> {{ $t("Close") }}</button>
                </div>
          </div>
      </div>
    </div>
    </teleport>
</template>

<script>
import { Modal } from 'bootstrap'
export default {
    name:"ModalTranslateError",
    mounted() {
        this.modal = new Modal(document.getElementById('modal-translate-error'));
    },
    computed : {
        translate_error(){
            return this.$store.getters['detail/translate_error']
        }
    },
    watch : {
        translate_error(){
            if(this.translate_error){
                this.modal.show()
            } else {
                this.modal.hide()
            }
        }
    },
    methods : {
        close_popup(){
            this.$store.dispatch('detail/set_translate_error', false)
        }
    }
}
</script>

<style>

</style>