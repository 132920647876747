<template>
    <div class="container-fluid page-detail">
        <div class="wrapper-box" id="detail">
            <template v-if="!isLoad">
                <template v-if="data">
                    <!-- BoitePresentation Header Start -->
                    <HeaderForBoitePresentation v-if="agency === 'BoitePresentation'"/>
                    <!-- BoitePresentation Header End -->

                    <!-- Detail Header Start -->
                    <HeaderForDrug v-else-if="data.agency_model"/>
                    <!-- Detail Header End -->

                    <!-- Detail Prix Header Start -->
                    <HeaderForPrix v-else-if="agency.startsWith('Prix')"/>
                    <!-- Detail Prix Header End -->

                    <component v-if="componentAgency" :is="componentAgency"></component>
                </template>
                

                <!-- show message text if don't have access -->
                <div class="box-white-fiche" v-else-if="message_error" v-html="message_error"></div>

                <!-- show message text if data is null -->
                <div class="box-white-fiche" v-else><h1>{{$t('No Data')}}</h1></div>

            </template>
        </div>
    </div>      

    <Loader :isLoad="isLoad" />

    <ModalTranslateError />
</template>

<script>
import ModalTranslateError from './ModalTranslateError.vue';
import { defineAsyncComponent } from "vue";
import Loader from '../Layout/Loader.vue'
import "../../assets/css/detail.css";

export default {
    name: 'detail',
    components: {
        ModalTranslateError,
        HeaderForBoitePresentation : defineAsyncComponent(() => import('./header/BoitePresentation.vue')),
        HeaderForDrug : defineAsyncComponent(() => import('./header/Drug.vue')),
        HeaderForPrix : defineAsyncComponent(() => import('./header/Prix.vue')),
        Loader
    },
    mounted(){
        this.$store.dispatch('detail/getdata', this.$route.params)
        document.body.classList.add('body-bg-gray')
        document.getElementById('header').style.position = "static"

        if(localStorage.getItem('language') !== this.$i18n.locale){
            window.location.reload()
        } 
    },
    updated(){
        document.getElementById('header').style.position = "fixed"
        document.getElementById('detail').classList.remove('mx-0')
        document.body.style = {'padding-top':'60px;'}
    },
    computed : {
        componentAgency(){
            return defineAsyncComponent(
                () => import(`./agency/${this.agency}.vue`)
            )
        },
        data(){
            return this.$store.getters['detail/data']
        },
        agency(){
            return this.$store.getters['detail/agency'];
        },
        isLoad(){
            return this.$store.getters['detail/load']
        },
        message_error(){
            return this.$store.getters['detail/error']
        }
    },
    unmounted(){
        document.body.classList.remove('body-bg-gray');
        this.$store.dispatch('detail/clearState')
    }
}
</script>

<style scoped>
.textJustify, .expandable{
    height: 100%;
}
</style>




